import { observer } from 'mobx-react';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from '../../foundation/methods/custom-hooks/useOnClickOutside';
import { deleteIcon } from '../../foundation/svg/delete-icon';
import { editIcon } from '../../foundation/svg/edit-icon';
import { exportIcon } from '../../foundation/svg/export-icon';
import { settingsIcon } from '../../foundation/svg/settings-icon';
import { spyGlassIcon } from '../../foundation/svg/spyglass-icon';
import { useStores } from '../../stores/StoreDistributor';
import { TextInput, TextInputType } from '../InputFields/TextInput/TextInput';
import { SortableAscendingSVG } from '../SortableSVG/SortableAscendingSVG';
import { SortableDefaultSVG } from '../SortableSVG/SortableDefaultSVG';
import { SortableDescendingSVG } from '../SortableSVG/SortableDescendingSVG';
import {
    Action,
    DataCellType,
    DataTableExtendedLabelAndValue,
    DataTableType,
    ExtendedCellData,
    IDataTableProps,
    ILink,
    ITableCell,
    ITableCellButton,
    ITableCellButtonModalProps,
} from './DataTableModels';

const IGNORED_KEY: string = 'uuid';

export const DataTable = observer(DataTableComponent);

function DataTableComponent<T extends unknown>({
    tableTitle,
    tableHeaders,
    tableData,
    handlers,
    tableType,
}: IDataTableProps<T>) {
    const { allowPortalStore } = useStores();

    const keysOfTableData = Object.keys((tableData.rowData?.length ? tableData.rowData[0] : []) as object).filter(
        (key) => key !== IGNORED_KEY
    );

    const getTableTypeClassname = (tableType: DataTableType | undefined): string => {
        switch (tableType) {
            case DataTableType.AllowPortal:
                return 'fls-data-table__allow-portal';
            case undefined:
            default:
                return '';
        }
    };

    const changeSortType = (key: any) => {
        if (allowPortalStore.sortElement !== keysOfTableData[key.toString()]) {
            allowPortalStore.sortDirection = 1;
        }
        allowPortalStore.sortElement = keysOfTableData[key.toString()];
        allowPortalStore.sortDirection = allowPortalStore.sortDirection + 1;
        if (allowPortalStore.sortDirection > 3) {
            allowPortalStore.sortDirection = 1;
        }
        allowPortalStore.FetchAllowPortalData();
    };

    const tableTypeClassname = getTableTypeClassname(tableType);

    return (
        <table className={`fls-data-table ${tableTypeClassname}`}>
            <caption className='fls-font__medium-bold fls-data-table__caption'>{tableTitle}</caption>
            <thead>
                <tr className='fls-data-table__row'>
                    {keysOfTableData.map((rowData, index) => {
                        const matchingHeaderForColumn = tableHeaders.find(
                            (header: DataTableExtendedLabelAndValue) => header.value === rowData
                        ) as DataTableExtendedLabelAndValue;
                        const alignRightHeaderClass = () =>
                            matchingHeaderForColumn?.textAlignRight ? 'fls-data-table__cell--align-right' : 'fls-data-table__header';
                        const restrictedCommentFieldHeader = () => matchingHeaderForColumn?.restrictedCommentField;

                        if (matchingHeaderForColumn?.sortDirection !== undefined) {
                            const currentlySortedElem = allowPortalStore.sortElement === keysOfTableData[index];
                            var sortSVG = <SortableDefaultSVG />;
                            if (currentlySortedElem) {
                                if (allowPortalStore.sortDirection === 1) {
                                    sortSVG = <SortableDefaultSVG />;
                                } else if (allowPortalStore.sortDirection === 2) {
                                    sortSVG = <SortableAscendingSVG />;
                                } else if (allowPortalStore.sortDirection === 3) {
                                    sortSVG = <SortableDescendingSVG />;
                                }
                            }

                            return (
                                <th
                                    className={`fls-font__small-bold fls-data-table__header ${alignRightHeaderClass()}`}
                                    style={{ width: '10%' }}
                                    key={index}
                                    scope='col'
                                >
                                    <button className='fls-data-table__button' onClick={() => changeSortType(index)}>
                                        {matchingHeaderForColumn?.label}
                                        {sortSVG}
                                    </button>
                                </th>
                            );
                        } else {
                            if (matchingHeaderForColumn?.hidden) {
                                return null;
                            }
                            if (restrictedCommentFieldHeader() === true) {
                                return (
                                    <th
                                        className={`fls-font__small-bold fls-data-table__header ${alignRightHeaderClass()}`}
                                        style={{ width: '14%' }}
                                        key={index}
                                        scope='col'
                                    >
                                        {matchingHeaderForColumn?.label}
                                    </th>
                                );
                            } else {
                                return (
                                    <th
                                        className={`fls-font__small-bold fls-data-table__header ${alignRightHeaderClass()}`}
                                        key={index}
                                        scope='col'
                                    >
                                        {matchingHeaderForColumn?.label}
                                    </th>
                                );
                            }
                        }
                    })}
                </tr>
            </thead>

            <tbody>
                {tableData.rowData?.map((tableRow: any, index) => {
                    return (
                        <tr className='fls-data-table__row' key={index}>
                            {Object.entries(tableRow)
                                .filter(([key]) => key !== IGNORED_KEY)
                                .map(([_, value], index) => {
                                    return (
                                        <TableCell
                                            key={index}
                                            tableCell={value as ExtendedCellData | ILink}
                                            handlers={handlers}
                                            collectionId={tableRow.uuid}
                                        />
                                    );
                                })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

const TableCell = <T extends unknown>({ tableCell, collectionId, handlers }: ITableCell<T & ExtendedCellData>) => {
    let computedCellValue: JSX.Element | JSX.Element[] | string = '-';
    const isTableCellArray = !!Array.isArray(tableCell);
    const isTableCellString = !!(typeof tableCell === 'string');
    let isTableCellTextAlignRight: boolean | undefined;
    let isTableCellNoBreak: boolean | undefined;
    const alignRightCellClass = () => (isTableCellTextAlignRight ? 'fls-data-table__cell--align-right' : '');
    const buttonCellClass = () => (isTableCellArray ? 'fls-data-table__cell--button-container' : '');
    const noBreakCellClass = () => (isTableCellNoBreak ? 'fls-data-table__cell--nobreak' : '');
    const [isChecked, setIsChecked] = useState(tableCell.value === 'true');
    const [selectedValue, setSelectedValue] = useState(tableCell.value);
    const hiddenCell = (tableCell as ExtendedCellData)?.hidden === true;
    useEffect(() => {
        setSelectedValue(tableCell.value);
        setIsChecked(tableCell.value === 'true');
    }, [tableCell.value]);

    if (isTableCellString) {
        computedCellValue = tableCell;
    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newCheckedValue = event.target.checked; // This is a boolean
        const callbackFunction = (tableCell as ExtendedCellData).callBack;
        if (typeof callbackFunction === 'function') {
            const newValue = newCheckedValue ? 'true' : 'false';
            callbackFunction(tableCell, newValue);
        }
    };

    const handleCheckboxClick = () => {
        setIsChecked((prevState) => !prevState);
        tableCell.value = !isChecked ? 'true' : 'false'; // Update tableCell.value
    };

    const isTableCellLinkObject = (tableCell: ILink | T): tableCell is ILink => {
        return tableCell !== null && (tableCell as ILink).value !== undefined;
    };

    const isTableCellObject = (tableCell: ExtendedCellData | ILink | T): tableCell is ExtendedCellData => {
        return tableCell !== null && (tableCell as ExtendedCellData).value !== undefined;
    };

    const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
        tableCell.value = newValue;

        const callbackFunction = (tableCell as ExtendedCellData).callBack;
        if (typeof callbackFunction === 'function') {
            callbackFunction(tableCell, newValue);
        }
    };

    if (isTableCellLinkObject(tableCell)) {
        computedCellValue = tableCell.url ? (
            <a className='fls-link' href={tableCell?.url ?? ''}>
                {tableCell?.value ?? '-'}
            </a>
        ) : (
            <span>{tableCell.value}</span>
        );
    }

    if (isTableCellArray) {
        const buttonList = tableCell.map((buttons: ITableCellButton, index: number) => (
            <TableCellButtonModal tableCellData={buttons} collectionId={collectionId} handlers={handlers} key={index} />
        ));
        computedCellValue = <div className='fls-data-table__edit-button-container'>{buttonList}</div>;
    }

    if (isTableCellObject(tableCell)) {
        isTableCellTextAlignRight = tableCell.textAlignRight;
        if (tableCell.cellType === DataCellType.Dropdown && tableCell.dropdownOptions) {
            computedCellValue = (
                <select className='fls-data-table__dropdown' onChange={handleDropdownChange} value={selectedValue}>
                    {tableCell.dropdownOptions.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            );
        } else if (tableCell.cellType === DataCellType.Checkbox) {
            computedCellValue = (
                <input
                    className='fls-data-table__checkbox'
                    type='checkbox'
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    onClick={handleCheckboxClick}
                />
            );
        } else if (tableCell.cellType === DataCellType.RestrictedCommentField) {
            const functionCall = (tableCell as ExtendedCellData).callBack;
            if (functionCall !== undefined) {
                computedCellValue = (
                    <TextInput
                        textInputType={TextInputType.RestrictedCommentFieldAllowPortal}
                        type=''
                        label=''
                        placeholder='Enter Comment'
                        autoFocus={false}
                        callBack={functionCall}
                        onBlur={functionCall}
                        additionalInfo={tableCell as ExtendedCellData}
                        defaultValue={tableCell.value}
                    />
                );
            }
        } else if (tableCell.cellType === DataCellType.Quotable || tableCell.value?.toLowerCase() === 'true' || tableCell.value?.toLowerCase() === 'false') {
            const isQuotable = tableCell.value?.toLocaleLowerCase() === 'true';

            if (isQuotable) {
                computedCellValue = (
                    <div style={tableCell.cellType === DataCellType.Quotable ? { textAlign: 'center' } : { textAlign: 'left' }}>
                        <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M12 1.25C5.78662 1.25 0.75 6.287 0.75 12.5C0.75 18.713 5.787 23.75 12 23.75C18.213 23.75 23.25 18.713 23.25 12.5C23.25 6.287 18.213 1.25 12 1.25ZM9.38437 19.25L9.37687 19.2425L9.3705 19.25L4.125 13.85L6.76087 11.1635L9.37725 13.8575L17.2523 5.75038L19.875 8.44963L9.38437 19.25Z'
                                fill='#43A047'
                            />
                        </svg>
                    </div>
                );
            } else if (!isQuotable) {
                computedCellValue = (
                    <div style={tableCell.cellType === DataCellType.Quotable ? { textAlign: 'center' } : { textAlign: 'left' }}>
                        <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <g clipPath='url(#clip0_360_975)'>
                                <g clipPath='url(#clip1_360_975)'>
                                    <path
                                        d='M12 24.5C18.6274 24.5 24 19.1274 24 12.5C24 5.87258 18.6274 0.5 12 0.5C5.37258 0.5 0 5.87258 0 12.5C0 19.1274 5.37258 24.5 12 24.5Z'
                                        fill='#DD3333'
                                    />
                                    <path
                                        d='M14.7 12.8025L17.6695 9.83295C17.858 9.64695 17.9525 9.41546 17.9525 9.14646C17.9525 8.87646 17.858 8.64796 17.6695 8.45946L16.296 7.08596C16.1075 6.89996 15.8785 6.80396 15.6095 6.80396C15.34 6.80396 15.111 6.89696 14.9225 7.08596L11.953 10.0555L8.98351 7.08596C8.79501 6.89996 8.56601 6.80396 8.29651 6.80396C8.02701 6.80396 7.79801 6.89696 7.60951 7.08596L6.23552 8.45946C6.04702 8.64846 5.95251 8.87696 5.95251 9.14646C5.95251 9.41646 6.04652 9.64495 6.23552 9.83295L9.20501 12.8025L6.23552 15.7725C6.04702 15.9585 5.95251 16.19 5.95251 16.459C5.95251 16.729 6.04652 16.9575 6.23552 17.1455L7.60951 18.519C7.79801 18.708 8.02701 18.801 8.29651 18.801C8.56601 18.801 8.79501 18.708 8.98351 18.519L11.953 15.5495L14.923 18.519C15.1115 18.708 15.3405 18.801 15.61 18.801C15.8795 18.801 16.1085 18.708 16.2965 18.519L17.67 17.1455C17.8585 16.9595 17.953 16.728 17.953 16.459C17.953 16.189 17.859 15.9605 17.67 15.7725L14.7005 12.8025'
                                        fill='white'
                                    />
                                </g>
                            </g>
                            <defs>
                                <clipPath id='clip0_360_975'>
                                    <rect width='24' height='24' fill='white' transform='translate(0 0.5)' />
                                </clipPath>
                                <clipPath id='clip1_360_975'>
                                    <rect width='24' height='24' fill='white' transform='translate(0 0.5)' />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                );
            }
        } else if (tableCell.cellType === DataCellType.Text) {
            computedCellValue = tableCell.value || '-';
        } else {
            computedCellValue = tableCell.value || '-';
        }
    }
    if (hiddenCell) {
        return <></>;
    }
    return (
        <td
            className={
                'fls-font__small-regular fls-data-table__cell ' +
                buttonCellClass() +
                ' ' +
                alignRightCellClass() +
                ' ' +
                noBreakCellClass()
            }
        >
            {computedCellValue}
        </td>
    );
};

const TableCellButtonModal = ({ tableCellData, collectionId, handlers }: ITableCellButtonModalProps) => {
    const buttonModal = useRef(null);
    const [active, setActive] = useState(false);

    const iconDict: Record<Action, ReactNode> = {
        export: exportIcon() as ReactNode,
        edit: settingsIcon() as ReactNode,
        delete: deleteIcon() as ReactNode,
        rename: editIcon() as ReactNode,
        open: spyGlassIcon() as ReactNode,
    };

    const toggleSettingsModal = () => {
        setActive(true);
    };

    const handleAction = (typeOfAction: Action) => {
        if (typeOfAction === 'delete') {
            handlers.deleteCollection!(collectionId);
        }
        if (typeOfAction === 'rename') {
            handlers.renameCollection!(collectionId);
        }
        if (typeOfAction === 'export') {
            handlers.exportCollection!(collectionId);
        }
        if (typeOfAction === 'open') {
            handlers.openCollection!(collectionId);
        }
    };

    const modalItemClass = (typeOfAction: Action) => {
        return typeOfAction === 'delete' ? `fls-data-table__edit-button-mini-modal-item--delete` : '';
    };

    useOnClickOutside(buttonModal, () => setActive(false));

    return (
        <div className='fls-data-table__edit-button-mini-modal-container'>
            <button
                onClick={
                    tableCellData.name === 'export' || tableCellData.name === 'open'
                        ? () => handleAction(tableCellData.name)
                        : () => toggleSettingsModal()
                }
                className='fls-data-table__edit-button'
            >
                <span>{iconDict[tableCellData.name as keyof typeof iconDict]}</span>
                <p>{tableCellData.name}</p>
            </button>

            <div
                ref={buttonModal}
                className={
                    'fls-data-table__edit-button-mini-modal ' + (active ? 'fls-data-table__edit-button-mini-modal--active' : '')
                }
            >
                {tableCellData.options &&
                    tableCellData.options.map((option: { name: Action }, index: number) => {
                        return (
                            <button
                                key={index}
                                className={'fls-data-table__edit-button-mini-modal-item ' + modalItemClass(option.name)}
                                onClick={() => handleAction(option.name)}
                            >
                                <span>{iconDict[option.name as keyof typeof iconDict]}</span>
                                <p className='fls-font__small-regular'>{option.name}</p>
                            </button>
                        );
                    })}
            </div>
        </div>
    );
};
