import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { deleteIcon } from '../../../foundation/svg/delete-icon';
import { IPCCustomerDetails } from '../../ApiClient/ApiClient';

interface CustomerTableProps {
  data: IPCCustomerDetails | undefined | null;
  deleteHandler: (customerId: string | undefined) => void;
}

export const CustomerDataTable: React.FC<CustomerTableProps> = ({ data, deleteHandler }) => {
  return (
    <section className='fls-sp-collection__content-section'>
      <Table className='fls-data-tablePC'>
        <TableHead className='fls-data-tablePC__header'>
          <TableRow className='fls-data-tablePC__row'>
            <TableCell
              sx={{ color: 'white', fontSize: 14, fontWeight: 'bold' }}
              className='fls-font__small-bold fls-data-tablePC__header'
            >
              Customer ID
            </TableCell>
            <TableCell
              sx={{ color: 'white', fontSize: 14, fontWeight: 'bold' }}
              className='fls-font__small-bold fls-data-tablePC__header'
            >
              Country
            </TableCell>
            <TableCell
              sx={{ color: 'white', fontSize: 14, fontWeight: 'bold' }}
              className='fls-font__small-bold fls-data-tablePC__header'
            >
              Curency
            </TableCell>
            <TableCell
              sx={{ color: 'white', fontSize: 14, fontWeight: 'bold' }}
              className='fls-font__small-bold fls-data-tablePC__header'
            >
              Contract End Date
            </TableCell>
            <TableCell
              align='left'
              sx={{ color: 'white', fontSize: 14, fontWeight: 'bold' }}
              className='fls-font__small-bold fls-data-tablePC__header'
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.customerDetailList?.map((row, index: number) => (
            <TableRow
              key={row.customerId}
              className='fls-data-tablePC__row'
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, fontSize: 12 }}
            >
              <TableCell sx={{ fontSize: 14 }}>
                <Link to={`/PricingAgreements/PartOverview/${row.customerId}?currency=${row.currency}`}>
                  {row.customerId}
                </Link>
              </TableCell>
              {/* <TableCell sx={{ fontSize: 14 }}>{row.country}</TableCell> */}
              <TableCell sx={{ fontSize: 14 }}>
                {row.countryCodeIso === '' ? 'Missing Country' : row.countryCodeIso}
              </TableCell>
              <TableCell sx={{ fontSize: 14 }}>{row.currency === '' ? 'Missing Currency' : row.currency}</TableCell>
              <TableCell sx={{ fontSize: 14 }}>
                {row.contractEndDate === '' ? 'Missing Contract End Date' : row.contractEndDate}
              </TableCell>
              <TableCell align='right' sx={{ fontSize: 14, float: 'inline-end' }}>
                <button
                  className='fls-data-tablePC__edit-button fls-data-tablePC__edit-button-mini-modal-item--delete'
                  onClick={() => deleteHandler(row.customerId)}
                >
                  <span>{deleteIcon()}</span>
                  <p>Delete</p>
                </button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </section>
  );
};
