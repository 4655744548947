import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AuthorizeView, RoleRequirement } from '../../components/AuthorizeView/AuthorizeView';
import { Button } from '../../components/Button/Button';
import { DataHealthTrafficLight } from '../../components/DataHealthTrafficLight/DataHealthTrafficLight';
import { DataTable } from '../../components/DataTable/DataTable';
import { SelectInput } from '../../components/InputFields/SelectInput/SelectInput';
import { ApproveDrawingModalContent } from '../../components/Modal/ModalComponents';
import { TabsSelector } from '../../components/TabsSelector/TabsSelector';
import { LoadingSpinner } from '../../foundation/svg/loading-spinner';
import { useStores } from '../../stores/StoreDistributor';
import { Client, IDataHealthState, IPart, TabData, TableData, TableElement } from '../ApiClient/ApiClient';
import { InPageError, InPageErrorProps } from '../InPageError/InPageError';

export const SparePartDetailPage = () => {
  const partId = useParams()['*'];
  const { detailStore } = useStores();
  const [localSPDetailData, setLocalSPDetailData] = useState<IPart | undefined>();
  const [errorConfig, setErrorConfig] = useState<InPageErrorProps | undefined>(undefined);

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<string | undefined>('');
  const [showIframe, setShowIframe] = useState(false);

  const [selectedNewTab, setSelectedTab] = useState<string | undefined>('');
  const [hiddenTabs, setHiddenTabs] = useState<Array<any> | undefined>([]);
  const selectLocation = (location: string) => setSelectedLocation(location);

  const selectNewTab = (option: TabData) => {
    let label = option.label;

    if (label) {
      setSelectedTab(label);
      activateTab(label);
    }
  };

  const loadData = () => {
    let apiClient = new Client();

    apiClient
      .part(partId!)
      .then((data: IPart) => {
        setLocalSPDetailData(data);
        if (data.locations) {
          if (
            data.locations.length === 2 ||
            (data.locations.length >= 2 &&
              'GLOBAL'.localeCompare(data.locations[1].label!, undefined, { sensitivity: 'base' }) === 0)
          ) {
            // Locations always contain "Select Input..." at index 0. Showing overall health of part.
            setSelectedLocation(data.locations[1].value); // When there is only one location on the part it is selected. SetSelectedLocation logic must also be implemented in the SelectInput.tsx, to make sure the state of the dropdown is reflected.
          } else {
            setSelectedLocation(data.locations[0].value);
          }
        }

        initialSetHiddenTabs(data.tabData);

        if (data.tabData) activateTab(data.tabData[0].label ?? '');
        setIsDataLoading(false);
        if (data.approvalPending) {
          //If there is a pending approval, try fetching the data again to see if it is done
          setTimeout(loadData, 15 * 1000);
        }
      })
      .catch((error) => {
        setErrorConfig({
          errorType: error.title,
          errorCode: error.status,
          buttons: [
            {
              type: 'primary',
              name: 'Return home',
              url: '/',
            },
            {
              type: '',
              name: 'Refresh page',
              url: '',
            },
          ],
        });
        setIsDataLoading(false);
      });
  };

  useEffect(() => {
    document.title = `${partId} - FLS GSC`;
    setIsDataLoading(true);
    window.scrollTo(0, 0);
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function to initially set hiddenTabs array - used in loadData()
  const initialSetHiddenTabs = (tabData: TabData[] | undefined) => {
    const hiddenTabs = tabData?.filter((tabDataItem) => tabDataItem.hiddenTab) || [];
    setHiddenTabs(hiddenTabs);
  };

  const IsIframeTab = (tabData: TabData) => {
    return tabData.iframeTab;
  };

  const activateTab = (label: string) => {
    setLocalSPDetailData((prevState) => {
      const tabData = prevState?.tabData ? [...prevState.tabData] : [];
      tabData.forEach((tabData) => (tabData.active = false));
      const tabToActivate = tabData.find((tabData) => tabData.label === label);

      if (tabToActivate && IsIframeTab(tabToActivate)) {
        setShowIframe(true);
      } else {
        setShowIframe(false);
      }

      if (tabToActivate && !tabToActivate.active) {
        tabToActivate.active = !tabToActivate.active;
      }

      return {
        ...prevState,
        tabData: tabData,
      };
    });
  };

  const locationLabel = () => {
    let label = localSPDetailData?.locations?.find((location) => location.value === selectedLocation)?.label;
    if (label === null) {
      label = selectedLocation;
    }
    return label;
  };

  const renderhighLightedPartInfo = () => {
    return (
      <ul className='fls-sp-detail__stacked-list'>
        {localSPDetailData?.highlighted?.partInfo?.map((partInfo, index: number) => {
          return (
            <li key={index} className='fls-sp-detail__stacked-list-item'>
              <span className='fls-font__xsmall-regular'>{partInfo?.label}</span>
              <p className='fls-font__small-bold fls-sp-detail__stacked-list-item-value-text'>{partInfo?.value}</p>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderhighLightedTypology = () => {
    return (
      <ul className='fls-sp-detail__stacked-list'>
        {localSPDetailData?.highlighted?.typology?.map((typology, index: number) => {
          return (
            <li key={index} className='fls-sp-detail__stacked-list-item'>
              <span className='fls-font__xsmall-regular'>{typology?.label}</span>
              <p className='fls-font__small-bold fls-sp-detail__stacked-list-item-list-text'>{typology?.value}</p>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderTabButtons = () => {
    return (
      <div className='fls-sp-detail__tablist' role='tablist'>
        {localSPDetailData?.tabData?.map((tabData: TabData, index: number) => {
          let numberOfRows: string | undefined;

          if (
            tabData.locationDependent &&
            tabData.showNumberOfRows &&
            tabData.locationToNumberOfRows &&
            selectedLocation
          ) {
            numberOfRows = tabData.locationToNumberOfRows[selectedLocation] ?? '';
          } else if (!tabData.locationDependent && tabData.showNumberOfRows && tabData.dataRowCount) {
            numberOfRows = tabData.dataRowCount ?? '';
          }

          if (!tabData.hiddenTab) {
            return (
              <button
                key={index}
                aria-selected={tabData.active}
                id={`tab-${tabData.label}`}
                onClick={() => activateTab(tabData.label ?? '')}
                className={
                  'fls-sp-detail__tab-button fls-font__small-bold ' +
                  (tabData.active ? 'fls-sp-detail__tab-button--active' : '')
                }
                type='button'
                role='tab'
              >
                {(tabData.label ?? '') + (numberOfRows ? ' (' + numberOfRows + ')' : '')}
              </button>
            );
          }

          if (selectedNewTab === tabData.label && tabData.active) {
            return (
              <button
                key={index}
                aria-selected={tabData.active}
                id={`tab-${tabData.label}`}
                onClick={() => activateTab(tabData.label ?? '')}
                className={
                  'fls-sp-detail__tab-button fls-font__small-bold ' +
                  (tabData.active ? 'fls-sp-detail__tab-button--active' : '')
                }
                type='button'
                role='tab'
              >
                {(tabData.label ?? '') + (numberOfRows ? ' (' + numberOfRows + ')' : '')}
              </button>
            );
          }
          return null;
        })}
      </div>
    );
  };

  const renderDataHealth = () => {
    const dataBySelectedLocation = localSPDetailData?.dataHealth?.dataHealthByLocation?.find(
      (datapoint) => datapoint.location === selectedLocation
    );
    return (
      <ul className='fls-sp-detail__data-health-list'>
        {dataBySelectedLocation &&
          dataBySelectedLocation.states?.map((dataHealthPoint: IDataHealthState, index: number) => {
            return (
              <li key={index}>
                <DataHealthTrafficLight key={index} {...dataHealthPoint} />
              </li>
            );
          })}
      </ul>
    );
  };

  const tableDataMatchingLocation = (tableData: TableData[]) => {
    return tableData.find((locationBasedTableData: TableData) => locationBasedTableData.location === selectedLocation);
  };

  const renderTabContent = () => {
    const renderDataTable = (tableElement: TableElement, tabPanel: TabData, tableElementIndex: number) => {
      let tableData: TableData | undefined;

      // If data is not dependent on location, we assume only one item is in the list
      if (tableElement.tableData) {
        tableData = tableElement.tableData[0];
      }

      if (tabPanel.locationDependent) {
        tableData = tableDataMatchingLocation(tableElement.tableData ?? []);
      }

      if (tabPanel.locationDependent && selectedLocation === '##_NO_LOCATION_##') {
        return (
          <div key={tableElementIndex} className='fls-sp-detail__table-error-msg fls-font__medium-bold'>
            {tableElement.tableTitle} data is determined by location. Please choose a location.
          </div>
        );
      }
      if (tableData?.rowData?.length) {
        return (
          <>
            <DataTable
              key={tableElementIndex}
              tableTitle={tableElement.tableTitle ?? ''}
              tableHeaders={tableElement.tableHeader ?? []}
              tableData={tableData}
              handlers={{
                deleteCollection: undefined,
                exportCollection: undefined,
                renameCollection: undefined,
                openCollection: undefined,
              }}
            />

            {tableElement.tableTitle === 'Cost Prices' && (
              <AuthorizeView roles={['CostPriceReader']} roleRequirement={RoleRequirement.Restricted}>
                <div className='restricted-data-message-container'>
                  <div className='restricted-data-message-box'>
                    <p className='fls-font__title restricted-data-exclamation'>!</p>

                    <p className='fls-font__medium-bold restricted-data-message'>
                      Insufficient permissions to view full cost price information.
                    </p>
                  </div>
                </div>
              </AuthorizeView>
            )}
          </>
        );
      }
      return (
        <div key={tableElementIndex} className='fls-sp-detail__table-error-msg fls-font__medium-bold fls-font__error'>
          {tabPanel.locationDependent
            ? `${tableElement.tableTitle} for location ${locationLabel()} could not be displayed.`
            : `${tableElement.tableTitle} data not available.`}
        </div>
      );
    };

    return (
      <div>
        {localSPDetailData?.tabData?.map((tabData: TabData, tabPanelIndex: number) => {
          if (tabData.active && selectedLocation && !tabData.iframeTab) {
            return (
              <section key={tabPanelIndex} role='tabpanel' tabIndex={0} aria-labelledby={`tab-${tabPanelIndex}`}>
                {tabData.tableElements?.map((tableElement: TableElement, tableElementIndex: number) => {
                  return renderDataTable(tableElement, tabData, tableElementIndex);
                })}
              </section>
            );
          }
          if (tabData.iframeTab && !tabData.tableElements) {
            return (
              <section key={tabPanelIndex} role='tabpanel' tabIndex={0} aria-labelledby={`tab-${tabPanelIndex}`}>
                <div className='fls-sp-detail__iframe-content' style={{ display: showIframe ? 'block' : 'none' }}>
                  <h2 className='fls-sp-detail__iframe-content-note fls-font__medium-title'>{tabData?.iframeNote}</h2>
                  <iframe src={tabData?.iframeUrl} frameBorder='0' title='iframe-content'></iframe>
                </div>
              </section>
            );
          }
          return null;
        })}
      </div>
    );
  };

  const renderApproveSalesPricesButtonIfAllowed = () => {
    const pending = localSPDetailData?.approvalPending ?? false;
    const icon = <span>&#10004;</span>;    
    
    return <></>;
  };

  return (
    <div className='fls-sp-detail'>
      <section className='fls-sp-detail__heading-section'>
        {!isDataLoading && (
          <div className='fls-sp-detail__heading-wrapper'>
            <div className='fls-sp-detail__part-number-wrapper'>
              <span className='fls-font__xsmall-regular fls-sp-detail__headerinfo-label'>
                {localSPDetailData?.headerInfo?.label}
              </span>
              <h1 className='fls-font__title'>{localSPDetailData?.headerInfo?.partNumber}</h1>
            </div>

            <div className='fls-sp-detail__part-number-wrapper'>
              <span className='fls-font__xsmall-regular fls-sp-detail__headerinfo-label'>Part Description</span>
              <p className='fls-font__small-regular'>{localSPDetailData?.headerInfo?.description}</p>
            </div>

            <div className='fls-sp-detail__part-number-wrapper'>
              <span className='fls-font__xsmall-regular fls-sp-detail__headerinfo-label'>Sales Activity</span>
              <p className='fls-font__small-regular'>{localSPDetailData?.headerInfo?.partLevel}</p>
            </div>

            <div className='fls-sp-detail__focused-info-approve-btn'>{renderApproveSalesPricesButtonIfAllowed()}</div>
          </div>
        )}
      </section>

      {errorConfig ? (
        <InPageError {...errorConfig} />
      ) : (
        <section className='fls-sp-detail__content-section'>
          <article className='fls-sp-detail__focused-info'>
            {isDataLoading ? (
              <div className='fls-spinner__background'>
                <LoadingSpinner />
              </div>
            ) : (
              <>
                <div className='fls-sp-detail__focused-info-main-container'>
                  <div className='fls-sp-detail__focused-info-wrapper'>{renderhighLightedPartInfo()}</div>

                  <div className='fls-sp-detail__focused-info-wrapper'>{renderhighLightedTypology()}</div>

                  <div className='fls-sp-detail__data-health'>
                    <h2 className='fls-font__small-bold'>{localSPDetailData?.dataHealth?.label}</h2>
                    {renderDataHealth()}
                  </div>
                </div>

                <div className='fls-sp-detail__location-select-and-tabs-wrapper'>
                  {localSPDetailData?.locations ? (
                    <SelectInput data={localSPDetailData?.locations} callBack={selectLocation} />
                  ) : null}

                  {renderTabButtons()}

                  {localSPDetailData?.tabData ? (
                    <TabsSelector data={hiddenTabs ? hiddenTabs : ''} callBack={selectNewTab} />
                  ) : null}
                </div>
              </>
            )}
          </article>
          {!isDataLoading && renderTabContent()}
        </section>
      )}
    </div>
  );
};
