import { createBrowserHistory } from 'history';
import { makeAutoObservable } from "mobx";
import { syncHistoryWithStore } from 'mobx-react-router';
import { Client, IFacetResult, ISearchResult, SearchRequest } from "../features/ApiClient/ApiClient";
import { SelectedFacets } from "../foundation/methods/facetCategoriesToSearchRequest";
import FacetStore from './FacetStore';
import { RootStore } from "./StoreDistributor";
import { encode, decode } from 'js-base64';

export default class PartSearchStore {
    private static readonly pageSizeQueryKey: string = "pageSize";
    private static readonly pageNumberQueryKey: string = "pageNumber";
    private static readonly searchStringQueryKey: string = "searchString";
    private static readonly facetFilterQueryKey: string = "facetFilters";

    rootStore: RootStore;

    searchString: string = "";

    facetStore: undefined | null | FacetStore = undefined;

    get isFacetsReady(): boolean {
        return this.facetStore != null;
    }

    get isFacetsLoading(): boolean {
        return this.facetStore === undefined;
    }

    openFacets: string[] = [ "Industry" ];

    toggleFacetDisplay = (name: string) => {
        if (this.openFacets.includes(name)) {
            this.openFacets = this.openFacets.filter(x => x !== name );
        } else {
            this.openFacets = this.openFacets.concat(name);
        }
    }

    isFacetOpen = (name: string): boolean => {
        return this.openFacets.includes(name);
    }

    currentPage: number = 1;    // Starting from 1
    get getLastPage(): number {
        if (this.searchResult !== null && this.searchResult?.totalCount !== undefined) {
            return Math.ceil(this.searchResult.totalCount / this.itemsPerPage);
        } else {
            return 1;   // One page if no parts index has been found
        }
    };

    public resetCurrentPage = () => {
        this.currentPage = 1;
    }

    itemsPerPage: number = 10; // default amount of items per page.

    // undefined for when no search has yet been performed
    // null for when a new search is performed
    searchResult: undefined | ISearchResult | null = undefined;

    get searchResultIsReady(): boolean {
        return this.searchResult != null;
    }

    get noSearchHasBeenPerformed(): boolean {
        return this.searchResult === undefined;
    }

    get searchResultIsLoading(): boolean {
        return this.searchResult === null;
    }

    error: { title: string, status: string } | undefined = undefined;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    public updatePartFacets = (): Promise<void> => {
        let apiClient = new Client();
        this.facetStore = null;

        return apiClient.facets()
            .then((facets: IFacetResult) => {
                this.facetStore = FacetStore.CreateFacetStore(facets.facetCategories ?? [])
            })
            .catch(error => {
                this.error = {
                    title: error.title,
                    status: error.status,
                };
            });
    }

    public updatePartSearch = (): Promise<void> => {
        let apiClient = new Client();
        this.searchResult = null;

        const facetRequests = this.facetStore?.getFacetRequest();

        this.setSearchParamsInUrl();

        let searchRequest = new SearchRequest({
            searchString: this.searchString,
            facetRequests: facetRequests,
            pageNumber: this.currentPage,
            itemsPerPage: this.itemsPerPage,
        });

        return apiClient.search(searchRequest)
            .then((searchResult: ISearchResult) => {
                this.searchResult = searchResult;
                this.facetStore?.updateFacets(searchResult.facets!);
            }, reject => {
                this.searchResult = null;
                console.log("Communication with spare part search API failed", reject);
            })
            .catch(error => {
                this.searchResult = null;
                console.log("Communication with spare part search API failed", error);
            });
    }

    public toggleFacetValueCheckbox = (toggledFacetValue: string, facetName: string) => {
        this.facetStore?.toggleSelectedFacetValue(facetName, toggledFacetValue);

        this.resetCurrentPage();
        this.updatePartSearch();
    }

    public setSearchParamsInUrl = () => {
        let selectedFacets: SelectedFacets = {};
        if (this.facetStore != null)
        {
            selectedFacets = this.facetStore.getSelectedFacets()
        }
        const params = PartSearchStore.getSearchQueryParams(this.itemsPerPage, this.currentPage, this.searchString, selectedFacets);

        const browserHistory = createBrowserHistory();
        const history = syncHistoryWithStore(browserHistory, this.rootStore.routerStore);
        history.replace({
            search: params.toString(),
        });
    }

    public static getSearchQueryParams = (pageSize: number, currentPage: number, searchString: string, chosenFacets: SelectedFacets): URLSearchParams => {
        const params = new URLSearchParams();
        params.append(PartSearchStore.pageSizeQueryKey, pageSize.toString());
        params.append(PartSearchStore.pageNumberQueryKey, currentPage.toString());
        params.append(PartSearchStore.searchStringQueryKey, searchString);

        const json = JSON.stringify(chosenFacets);
        const base64 = encode(json) // Uses JS.Base64 library to encode.
        params.append(PartSearchStore.facetFilterQueryKey, base64);
        return params;
    }

    public fetchSearchParamsFromUrl = () => {
        const url = new URL(window.location.toString());
        const params = url.searchParams;

        const itemsPerPage = params.get(PartSearchStore.pageSizeQueryKey);
        const currentPage = params.get(PartSearchStore.pageNumberQueryKey);
        if (itemsPerPage !== null && currentPage) {
            this.itemsPerPage = parseInt(itemsPerPage);
            this.currentPage = parseInt(currentPage);
        }

        const searchString = params.get(PartSearchStore.searchStringQueryKey);
        if (searchString !== null) {
            this.searchString = searchString;
        }

        const facetFiltersBase64 = params.get(PartSearchStore.facetFilterQueryKey);
        if (facetFiltersBase64 !== null) {
            const json = decode(facetFiltersBase64); // Uses JS.Base64 library to decode.
            const selectedFacets: SelectedFacets = JSON.parse(json);

            for (const selectedFacetName in selectedFacets) {

                const selectedFacetValues = selectedFacets[selectedFacetName];
                (this.facetStore?.facetCategories ?? []).forEach(category => {
                    category.facetDropdowns.forEach(facet => {
                        if (facet.name === selectedFacetName) {
                            facet.facets.forEach(fValue => {
                                if (selectedFacetValues.some(selectedFacetValue =>
                                    selectedFacetValue === fValue.value)) {
                                    fValue.selected = true;
                                    facet.open = true;
                                }
                            })
                        }
                    })
                });
            }
        }
    }

    
}
