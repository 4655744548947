/* eslint-disable react/jsx-no-target-blank */
import { NavLink } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { RoleTypes } from '../../constants/GenericContants';
import { FLSBoxlogo } from '../../foundation/svg/fls-box-logo';
import routes from '../../routes/AppRoutes';
import { useStores } from '../../stores/StoreDistributor';
import { AuthorizeView, RoleRequirement } from '../AuthorizeView/AuthorizeView';

export const NavMenu = () => {
  const navLinkBaseClass = 'fls-navigation__nav-item fls-font__medium-bold';
  const settingsIcon = <span>&#9881;</span>;
  const { localeSettingsStore, detailStore } = useStores();

  const getPricingToolUrl = (): string => {
    switch (window.location.hostname) {
      case 'spc.cloud.flsmidth.com':
        return 'pricing.flsmidth.com';
      case 'app-spc-catalogue-api-01-u.azurewebsites.net':
      case 'uat.spc.cloud.flsmidth.com':
        return 'app-spc-pricingtool-api-01-u.azurewebsites.net';
      case 'app-spc-catalogue-api-01-t.azurewebsites.net':
      case 'sit01.spc.cloud.flsmidth.com':
        return 'app-spc-pricingtool-api-01-t.azurewebsites.net';
      case 'app-spc-catalogue-api-01-d.azurewebsites.net':
      case 'dev01.spc.cloud.flsmidth.com':
        return 'app-spc-pricingtool-api-01-d.azurewebsites.net';
      case 'localhost':
        return 'app-spc-pricingtool-api-01-d.azurewebsites.net';
      default:
        return '';
    }
  };

  var envname;
  switch (window.location.hostname) {
    case 'spc.cloud.flsmidth.com':
      envname = 'PROD';
      break;
    case 'app-spc-catalogue-api-01-u.azurewebsites.net':
    case 'uat.spc.cloud.flsmidth.com':
      envname = 'UAT';
      break;
    case 'app-spc-catalogue-api-01-t.azurewebsites.net':
    case 'sit01.spc.cloud.flsmidth.com':
      envname = 'SIT';
      break;
    case 'app-spc-catalogue-api-01-d.azurewebsites.net':
    case 'dev01.spc.cloud.flsmidth.com':
      envname = 'DEV';
      break;
    default:
      envname = window.location.hostname;
  }
  const isprod = envname === 'PROD';

  return (
    <header className='fls-navigation'>
      <div className='fls-navigation__title-container'>
        <a className='fls-navigation__page-title-link' href='/'>
          <h1 className='fls-font__title fls-navigation__page-title'>Global Service Catalogue</h1>
        </a>

        {isprod ? null : (
          <div className='fls-navigation__container--alert'>
            <p>{`NON PROD: ${envname}`}</p>
          </div>
        )}
      </div>
      <div className='fls-navigation__container'>
        <ul className='fls-navigation__nav-items'>
          <AuthorizeView roles={[RoleTypes.PricingToolAccess]} roleRequirement={RoleRequirement.Required}>
            <li className=''>
              <a
                href={`https://${getPricingToolUrl()}`}
                target='_blank'
                className='fls-navigation__nav-item fls-font__medium-bold'
              >
                Pricing Tool
              </a>
            </li>
          </AuthorizeView>
          <AuthorizeView roles={[RoleTypes.GPCAccess]} roleRequirement={RoleRequirement.Required}>
            <li className=''>
              <NavLink className={navLinkBaseClass} to='/allow-portal'>
                Allow Portal
              </NavLink>
            </li>
          </AuthorizeView>

          <AuthorizeView roles={[RoleTypes.SPCAdmin]} roleRequirement={RoleRequirement.Required}>
            <li className=''>
              <NavLink className={navLinkBaseClass} to='/PricingAgreements/CustomerOverview'>
                Pricing Agreements
              </NavLink>
            </li>
          </AuthorizeView>

          <li className={navLinkBaseClass}>
            <Button
              text={'Nominate Parts'}
              type='primary-with-icon-settings'
              action={() => {
                detailStore.addNominatePartModal();
              }}
            ></Button>
          </li>
          {routes
            .filter((route) => route.name)
            .map((route, index) => {
              return (
                <li key={index} className=''>
                  <NavLink
                    className={(nav) =>
                      nav.isActive ? navLinkBaseClass + ' fls-navigation__nav-item--active' : navLinkBaseClass
                    }
                    to={route.path}
                  >
                    {route.name}
                  </NavLink>
                </li>
              );
            })}
          <li className={navLinkBaseClass}>
            <Button
              text={'Feedback'}
              type='primary-with-icon-settings'
              action={() => {
                detailStore.addFeedbackModal();
              }}
            ></Button>
          </li>
          <li>
            <Button
              text={''}
              type='primary-with-icon-settings'
              icon={settingsIcon}
              action={() => {
                localeSettingsStore.addLocaleSettingsModal();
              }}
            ></Button>
          </li>
        </ul>

        <a className='fls-main-logo__box-logo' href='/'>
          {FLSBoxlogo(61)}
        </a>
      </div>
    </header>
  );
};
